// extracted by mini-css-extract-plugin
export var close = "oldWorks-module--close--zrxd+";
export var curThumNail = "oldWorks-module--curThumNail--EieGq";
export var decription = "oldWorks-module--decription--kt9N3";
export var desc = "oldWorks-module--desc--lCInG";
export var eachTitle = "oldWorks-module--eachTitle--5eBrv";
export var imgAppear = "oldWorks-module--imgAppear--W1iGK";
export var imgHide = "oldWorks-module--imgHide--CgBTv";
export var isShowCSS = "oldWorks-module--isShowCSS--v9fPl";
export var modalArea = "oldWorks-module--modalArea--gCng-";
export var modalBg = "oldWorks-module--modalBg--ziihD";
export var modalContent = "oldWorks-module--modalContent--mulyT";
export var modalContentCSS = "oldWorks-module--modalContentCSS--6liAL";
export var modalWrapper = "oldWorks-module--modalWrapper--ZLMN3";
export var modalWrapperScale = "oldWorks-module--modalWrapperScale--3A-W7";
export var modalWrapperWrapper = "oldWorks-module--modalWrapperWrapper--YdmqN";
export var thumNail = "oldWorks-module--thumNail--PPAko";
export var thumNailUme = "oldWorks-module--thumNailUme--MNFqU";
export var thumNails = "oldWorks-module--thumNails--ScROi";
export var thumbImg = "oldWorks-module--thumbImg--MzEgZ";